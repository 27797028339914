import React from "react";
import * as Icons from "@src/components/nessie-web/Icons";

export type IconName = keyof typeof iconsList;

const IconFromString: React.FC<{ iconName: IconName; [key: string]: any }> = ({ iconName, ...otherProps }) => {
  const SelectedIcon = iconsList[iconName];

  return (
    <>
      <SelectedIcon {...otherProps} />
    </>
  );
};

export default IconFromString;

const iconsList = {
  ActivityIcon: Icons.ActivityIcon,
  AddIcon: Icons.AddIcon,
  AlertIcon: Icons.AlertIcon,
  ArchiveIcon: Icons.ArchiveIcon,
  ArrowLeftIcon: Icons.ArrowLeftIcon,
  ArrowRightIcon: Icons.ArrowRightIcon,
  AtomIcon: Icons.AtomIcon,
  BookIcon: Icons.BookIcon,
  CalendarIcon: Icons.CalendarIcon,
  CallIcon: Icons.CallIcon,
  CameraFlashIcon: Icons.CameraFlashIcon,
  CameraIcon: Icons.CameraIcon,
  CameraSwitchIcon: Icons.CameraSwitchIcon,
  CameraVideoIcon: Icons.CameraVideoIcon,
  CastIcon: Icons.CastIcon,
  ChartIcon: Icons.ChartIcon,
  CheckIcon: Icons.CheckIcon,
  CheckboxEmptyIcon: Icons.CheckboxEmptyIcon,
  CheckboxSelectedIcon: Icons.CheckboxSelectedIcon,
  CheckmarkIcon: Icons.CheckmarkIcon,
  ChevronDownIcon: Icons.ChevronDownIcon,
  ChevronLeftIcon: Icons.ChevronLeftIcon,
  ChevronRightIcon: Icons.ChevronRightIcon,
  ChevronSmallDownIcon: Icons.ChevronSmallDownIcon,
  ChevronSmallLeftIcon: Icons.ChevronSmallLeftIcon,
  ChevronSmallRightIcon: Icons.ChevronSmallRightIcon,
  ChevronSmallUpIcon: Icons.ChevronSmallUpIcon,
  ChevronUpIcon: Icons.ChevronUpIcon,
  ClassroomIcon: Icons.ClassroomIcon,
  ClockIcon: Icons.ClockIcon,
  CloseIcon: Icons.CloseIcon,
  CloseSmallIcon: Icons.CloseSmallIcon,
  CommentIcon: Icons.CommentIcon,
  ComputerIcon: Icons.ComputerIcon,
  DeleteIcon: Icons.DeleteIcon,
  DownCarrotIcon: Icons.DownCarrotIcon,
  DownloadIcon: Icons.DownloadIcon,
  EyeIcon: Icons.EyeIcon,
  FacebookIcon: Icons.FacebookIcon,
  FileIcon: Icons.FileIcon,
  FlagIcon: Icons.FlagIcon,
  FolderIcon: Icons.FolderIcon,
  FullscreenExitIcon: Icons.FullscreenExitIcon,
  FullscreenIcon: Icons.FullscreenIcon,
  GlobeIcon: Icons.GlobeIcon,
  GraduationIcon: Icons.GraduationIcon,
  HeartIcon: Icons.HeartIcon,
  HelpIcon: Icons.HelpIcon,
  HomeIcon: Icons.HomeIcon,
  IdeasIcon: Icons.IdeasIcon,
  InviteIcon: Icons.InviteIcon,
  LightbulbIcon: Icons.LightbulbIcon,
  LinkIcon: Icons.LinkIcon,
  ListIcon: Icons.ListIcon,
  LockIcon: Icons.LockIcon,
  LoginIcon: Icons.LoginIcon,
  LogoutIcon: Icons.LogoutIcon,
  MailIcon: Icons.MailIcon,
  MathIcon: Icons.MathIcon,
  MergeIcon: Icons.MergeIcon,
  MessagesIcon: Icons.MessagesIcon,
  MicrophoneIcon: Icons.MicrophoneIcon,
  MinusIcon: Icons.MinusIcon,
  MoviesIcon: Icons.MoviesIcon,
  MusicIcon: Icons.MusicIcon,
  NavigationMenuIcon: Icons.NavigationMenuIcon,
  NewsIcon: Icons.NewsIcon,
  NotificationIcon: Icons.NotificationIcon,
  NotificationOffIcon: Icons.NotificationOffIcon,
  OverflowIcon: Icons.OverflowIcon,
  PaintBrushIcon: Icons.PaintBrushIcon,
  PaintIcon: Icons.PaintIcon,
  PauseIcon: Icons.PauseIcon,
  PencilIcon: Icons.PencilIcon,
  PeopleIcon: Icons.PeopleIcon,
  PersonIcon: Icons.PersonIcon,
  PhoneIcon: Icons.PhoneIcon,
  PhotoIcon: Icons.PhotoIcon,
  PhotoLibraryIcon: Icons.PhotoLibraryIcon,
  PlayIcon: Icons.PlayIcon,
  PlayVideoIcon: Icons.PlayVideoIcon,
  PlusBrandIcon: Icons.PlusBrandIcon,
  PlusIcon: Icons.PlusIcon,
  PortraitFemaleIcon: Icons.PortraitFemaleIcon,
  PortraitIcon: Icons.PortraitIcon,
  PortraitMaleIcon: Icons.PortraitMaleIcon,
  PrintIcon: Icons.PrintIcon,
  QrCodeIcon: Icons.QrCodeIcon,
  QuoteIcon: Icons.QuoteIcon,
  RepeatIcon: Icons.RepeatIcon,
  ResetIcon: Icons.ResetIcon,
  RewardIcon: Icons.RewardIcon,
  RoundAlertIcon: Icons.RoundAlertIcon,
  SchoolwideBadgeIcon: Icons.SchoolwideBadgeIcon,
  SearchIcon: Icons.SearchIcon,
  SelectIcon: Icons.SelectIcon,
  SendIcon: Icons.SendIcon,
  SettingsIcon: Icons.SettingsIcon,
  ShuffleIcon: Icons.ShuffleIcon,
  SkillRemoveIcon: Icons.SkillRemoveIcon,
  SkillsAddIcon: Icons.SkillsAddIcon,
  SkipBack10Icon: Icons.SkipBack10Icon,
  SkipForward10Icon: Icons.SkipForward10Icon,
  SortIcon: Icons.SortIcon,
  StarIcon: Icons.StarIcon,
  StickerIcon: Icons.StickerIcon,
  StopIcon: Icons.StopIcon,
  StoryIcon: Icons.StoryIcon,
  StudentIcon: Icons.StudentIcon,
  SunIcon: Icons.SunIcon,
  ThoughtCloudIcon: Icons.ThoughtCloudIcon,
  TimerIcon: Icons.TimerIcon,
  ToolkitIcon: Icons.ToolkitIcon,
  TwitterIcon: Icons.TwitterIcon,
  UndoIcon: Icons.UndoIcon,
  UnlockIcon: Icons.UnlockIcon,
  VideoIcon: Icons.VideoIcon,
  XCircleIcon: Icons.XCircleIcon,
  ZapIcon: Icons.ZapIcon,
} as const;
